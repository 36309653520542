var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"body-class":"p-0"}},[_c('b-card-header',[_c('b-row',{staticClass:"w-100",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"mb-2 mb-sm-0",attrs:{"sm":"6"}},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Akreditasi Program Studi "+_vm._s(_vm.programStudi)+" "+_vm._s(_vm.tingkatanStudi)+" BAN-PT ")])])],1)],1),_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',{staticClass:"px-3 py-2"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Peringkat Akreditasi PS","label-for":"peringkat-akreditasi-ps"}},[_c('validation-provider',{attrs:{"name":"Peringkat Akreditasi PS","vid":"peringkat-akreditasi-ps","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"select",attrs:{"id":"peringkat-akreditasi-ps","options":_vm.vSelectAkreditasi.options,"reduce":_vm.vSelectAkreditasi.reduce,"disabled":_vm.fetchLoading},model:{value:(_vm.form.peringkat_akreditasi_ps),callback:function ($$v) {_vm.$set(_vm.form, "peringkat_akreditasi_ps", $$v)},expression:"form.peringkat_akreditasi_ps"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Nomor SK BAN-PT","label-for":"nomor-sk-ban-pt"}},[_c('validation-provider',{attrs:{"name":"Nomor SK BAN-PT","vid":"nomor-sk-ban-pt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nomor-sk-ban-pt","disabled":_vm.fetchLoading},model:{value:(_vm.form.nomor_sk),callback:function ($$v) {_vm.$set(_vm.form, "nomor_sk", $$v)},expression:"form.nomor_sk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Tanggal Kadaluarsa","label-for":"tanggal-kadaluarsa"}},[_c('validation-provider',{attrs:{"name":"Tanggal Kadaluarsa","vid":"tanggal-kadaluarsa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"tanggal-kadaluarsa","static":"true","config":_vm.dpconfig},model:{value:(_vm.form.tanggal_kdl),callback:function ($$v) {_vm.$set(_vm.form, "tanggal_kdl", $$v)},expression:"form.tanggal_kdl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Nama Unit Pengelola","label-for":"nama-unit-pengelola"}},[_c('validation-provider',{attrs:{"name":"Nama Unit Pengelola","vid":"nama-unit-pengelola","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nama-unit-pengelola","disabled":_vm.fetchLoading},model:{value:(_vm.form.nama_unit_pengelola),callback:function ($$v) {_vm.$set(_vm.form, "nama_unit_pengelola", $$v)},expression:"form.nama_unit_pengelola"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Nama Perguruan Tinggi","label-for":"nama-perguruan-tinggi"}},[_c('validation-provider',{attrs:{"name":"Nama Perguruan Tinggi","vid":"nama-perguruan-tinggi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nama-perguruan-tinggi","disabled":true},model:{value:(_vm.form.nama_perguruan_tinggi),callback:function ($$v) {_vm.$set(_vm.form, "nama_perguruan_tinggi", $$v)},expression:"form.nama_perguruan_tinggi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Alamat","label-for":"alamat-perguruan-tinggi"}},[_c('validation-provider',{attrs:{"name":"Alamat Perguruan Tinggi","vid":"alamat-perguruan-tinggi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"alamat-perguruan-tinggi"},model:{value:(_vm.form.alamat),callback:function ($$v) {_vm.$set(_vm.form, "alamat", $$v)},expression:"form.alamat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Kota/Kabupaten","label-for":"kota-Kabupaten"}},[_c('validation-provider',{attrs:{"name":"Kota/Kabupaten","vid":"kota-Kabupaten","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kota-Kabupaten"},model:{value:(_vm.form.kota_kab),callback:function ($$v) {_vm.$set(_vm.form, "kota_kab", $$v)},expression:"form.kota_kab"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Kode Pos","label-for":"kode-pos"}},[_c('validation-provider',{attrs:{"name":"Kode Pos","vid":"kode-pos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kode-pos","type":"number","disabled":_vm.fetchLoading},model:{value:(_vm.form.kode_pos),callback:function ($$v) {_vm.$set(_vm.form, "kode_pos", $$v)},expression:"form.kode_pos"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Nomor Telepon","label-for":"nomor-telepon"}},[_c('validation-provider',{attrs:{"name":"Nomor Telepon","vid":"nomor-telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nomor-telepon","type":"number","disabled":_vm.fetchLoading},model:{value:(_vm.form.nomor_telp),callback:function ($$v) {_vm.$set(_vm.form, "nomor_telp", $$v)},expression:"form.nomor_telp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","disabled":_vm.fetchLoading},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Website","label-for":"website"}},[_c('validation-provider',{attrs:{"name":"Website","vid":"website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"website","disabled":_vm.fetchLoading},model:{value:(_vm.form.website),callback:function ($$v) {_vm.$set(_vm.form, "website", $$v)},expression:"form.website"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"TS *)","label-for":"ts"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"Dari Tahun","vid":"ts_dari","rules":"required|digits:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ts_dari","type":"number","min":"1000","max":"9999","disabled":_vm.fetchLoading},model:{value:(_vm.form.ts_dari),callback:function ($$v) {_vm.$set(_vm.form, "ts_dari", $$v)},expression:"form.ts_dari"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"d-flex justify-content-center",staticStyle:{"padding-top":"0.35em"},attrs:{"cols":"2"}},[_c('span',{style:({ fontSize: '18px' })},[_vm._v(" / ")])]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-input',{attrs:{"id":"ts_sampai","disabled":_vm.fetchLoading,"readonly":""},model:{value:(_vm.form.ts_sampai),callback:function ($$v) {_vm.$set(_vm.form, "ts_sampai", $$v)},expression:"form.ts_sampai"}})],1)],1)],1),_c('span',{staticClass:"text-secondary mb-1 d-inline-block"},[_c('i',[_vm._v("*) TS = Tahun akademik penuh terakhir saat pengajuan usulan akreditasi")])]),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Nama Pengusul","label-for":"nama-pengusul"}},[_c('validation-provider',{attrs:{"name":"Nama Pengusul","vid":"nama-pengusul","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nama-pengusul","disabled":_vm.fetchLoading},model:{value:(_vm.form.name_pengusul),callback:function ($$v) {_vm.$set(_vm.form, "name_pengusul", $$v)},expression:"form.name_pengusul"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Tanggal","label-for":"tanggal"}},[_c('validation-provider',{attrs:{"name":"Tanggal","vid":"tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"tanggal","static":"true","config":_vm.dpconfig},model:{value:(_vm.form.tanggal),callback:function ($$v) {_vm.$set(_vm.form, "tanggal", $$v)},expression:"form.tanggal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-card-footer',{staticClass:"text-right"},[_c('b-button',{staticClass:"button-pilih mr-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.loading ? 'Menyimpan...' : 'Simpan')}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
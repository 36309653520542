<template>
  <b-card body-class="p-0">
    <!-- Header -->
    <b-card-header>
      <b-row
        class="w-100"
        align-v="center"
      >
        <b-col
          class="mb-2 mb-sm-0"
          sm="6"
        >
          <h4 class="mb-0">
            Akreditasi Program Studi {{ programStudi }} {{ tingkatanStudi }} BAN-PT
          </h4>
        </b-col>
      </b-row>
    </b-card-header>

    <validation-observer ref="form">
      <b-form @submit.prevent="onSubmit">
        <!-- Body -->
        <b-row class="px-3 py-2">
          <b-col lg="6">
            <!-- Peringkat Akreditasi PS -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Peringkat Akreditasi PS"
              label-for="peringkat-akreditasi-ps"
            >
              <validation-provider
                #default="{ errors }"
                name="Peringkat Akreditasi PS"
                vid="peringkat-akreditasi-ps"
                rules="required"
              >
                <!-- <b-form-input
                  id="peringkat-akreditasi-ps"
                  v-model="form.peringkat_akreditasi_ps"
                  :disabled="fetchLoading"
                /> -->

                <v-select
                  id="peringkat-akreditasi-ps"
                  ref="select"
                  v-model="form.peringkat_akreditasi_ps"
                  :options="vSelectAkreditasi.options"
                  :reduce="vSelectAkreditasi.reduce"
                  :disabled="fetchLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Nomor SK BAN-PT -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Nomor SK BAN-PT"
              label-for="nomor-sk-ban-pt"
            >
              <validation-provider
                #default="{ errors }"
                name="Nomor SK BAN-PT"
                vid="nomor-sk-ban-pt"
                rules="required"
              >
                <b-form-input
                  id="nomor-sk-ban-pt"
                  v-model="form.nomor_sk"
                  :disabled="fetchLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Tanggal Kadaluarsa -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Tanggal Kadaluarsa"
              label-for="tanggal-kadaluarsa"
            >
              <validation-provider
                #default="{ errors }"
                name="Tanggal Kadaluarsa"
                vid="tanggal-kadaluarsa"
                rules="required"
              >
                <!-- <b-form-datepicker
                  id="tanggal-kadaluarsa"
                  v-model="form.tanggal_kdl"
                  locale="id"
                /> -->
                <flat-pickr
                  id="tanggal-kadaluarsa"
                  v-model="form.tanggal_kdl"
                  class="form-control"
                  static="true"
                  :config="dpconfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Nama Unit Pengelola -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Nama Unit Pengelola"
              label-for="nama-unit-pengelola"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Unit Pengelola"
                vid="nama-unit-pengelola"
                rules="required"
              >
                <b-form-input
                  id="nama-unit-pengelola"
                  v-model="form.nama_unit_pengelola"
                  :disabled="fetchLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Nama Perguruan Tinggi -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Nama Perguruan Tinggi"
              label-for="nama-perguruan-tinggi"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Perguruan Tinggi"
                vid="nama-perguruan-tinggi"
                rules="required"
              >
                <b-form-input
                  id="nama-perguruan-tinggi"
                  v-model="form.nama_perguruan_tinggi"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Alamat Perguruan Tinggi -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Alamat"
              label-for="alamat-perguruan-tinggi"
            >
              <validation-provider
                #default="{ errors }"
                name="Alamat Perguruan Tinggi"
                vid="alamat-perguruan-tinggi"
                rules="required"
              >
                <b-form-textarea
                  id="alamat-perguruan-tinggi"
                  v-model="form.alamat"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Kota -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Kota/Kabupaten"
              label-for="kota-Kabupaten"
            >
              <validation-provider
                #default="{ errors }"
                name="Kota/Kabupaten"
                vid="kota-Kabupaten"
                rules="required"
              >
                <b-form-input
                  id="kota-Kabupaten"
                  v-model="form.kota_kab"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <!-- Kode Pos -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Kode Pos"
              label-for="kode-pos"
            >
              <validation-provider
                #default="{ errors }"
                name="Kode Pos"
                vid="kode-pos"
                rules="required"
              >
                <b-form-input
                  id="kode-pos"
                  v-model="form.kode_pos"
                  type="number"
                  :disabled="fetchLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Nomor Telepon -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Nomor Telepon"
              label-for="nomor-telepon"
            >
              <validation-provider
                #default="{ errors }"
                name="Nomor Telepon"
                vid="nomor-telepon"
                rules="required"
              >
                <b-form-input
                  id="nomor-telepon"
                  v-model="form.nomor_telp"
                  type="number"
                  :disabled="fetchLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Email -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  :disabled="fetchLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Website -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Website"
              label-for="website"
            >
              <validation-provider
                #default="{ errors }"
                name="Website"
                vid="website"
                rules="required"
              >
                <b-form-input
                  id="website"
                  v-model="form.website"
                  :disabled="fetchLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- TS -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="TS *)"
              label-for="ts"
            >
              <b-row>
                <b-col cols="5">
                  <!-- TS Dari -->
                  <validation-provider
                    #default="{ errors }"
                    name="Dari Tahun"
                    vid="ts_dari"
                    rules="required|digits:4"
                  >
                    <b-form-input
                      id="ts_dari"
                      v-model="form.ts_dari"
                      type="number"
                      min="1000"
                      max="9999"
                      :disabled="fetchLoading"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="2"
                  class="d-flex justify-content-center"
                  style="padding-top: 0.35em"
                >
                  <span :style="{ fontSize: '18px' }">
                    /
                  </span>
                </b-col>
                <b-col cols="5">
                  <!-- TS Akhir -->
                  <b-form-input
                    id="ts_sampai"
                    v-model="form.ts_sampai"
                    :disabled="fetchLoading"
                    readonly
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <span class="text-secondary mb-1 d-inline-block"><i>*) TS = Tahun akademik penuh terakhir saat pengajuan usulan akreditasi</i></span>
            <!-- Nama Pengusul -->
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Nama Pengusul"
              label-for="nama-pengusul"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Pengusul"
                vid="nama-pengusul"
                rules="required"
              >
                <b-form-input
                  id="nama-pengusul"
                  v-model="form.name_pengusul"
                  :disabled="fetchLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Tanggal"
              label-for="tanggal"
            >
              <validation-provider
                #default="{ errors }"
                name="Tanggal"
                vid="tanggal"
                rules="required"
              >
                <!-- <b-form-datepicker
                  id="tanggal"
                  v-model="form.tanggal"
                  locale="id"
                /> -->
                <flat-pickr
                  id="tanggal"
                  v-model="form.tanggal"
                  class="form-control"
                  static="true"
                  :config="dpconfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Footer -->
        <b-card-footer class="text-right">
          <b-button
            class="button-pilih mr-1"
            variant="primary"
            type="submit"
            :disabled="loading"
            v-text="loading ? 'Menyimpan...' : 'Simpan'"
          />
        </b-card-footer>
      </b-form>
    </validation-observer>

    <!-- Delete department modal -->
    <!-- <b-modal
      centered
      id="generate-new-ts"
      title="Tahun Semester"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      cancel-variant="secondary"
      cancel-title="Batal"
      ok-variant="primary"
      ok-title="Iya"
      :ok-disabled="generateNewTsLoading"
      @ok="() => generateNewTS()"
    >
      <b-card-text class="text-center">
        <h4 class="mb-0">Tetapkan tahun semester <span v-text="`${this.ts.dari} / ${this.ts.sampai}`" />?</h4>
      </b-card-text>
    </b-modal> -->
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormGroup,
  BForm,
  BFormDatepicker,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'

import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { required, digits, email } from '@validations'
import moment from 'moment'
import { getUserData } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormGroup,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker,
    VSelect,
  },
  data() {
    return {
      dpconfig: {
        wrap: true,
        altInput: true,
        // dateFormat: 'Y',
        // altFormat: 'Y',
        // ariaDateFormat: 'Y',
        locale: Indonesian,
        defaultDate: 'today',
        onReady: this.flatPickrOnReady,
        // onOpen : function(event){
        //   let instances = document.getElementsByClassName("flatpickr-innerContainer");
        //   for(let i= 0; i< instances.length; i+=1){
        //     instances[i].style.display="none";
        //   }
        // },
      },
      programStudi: getUserData().departments[0].program_studi,
      tingkatanStudi: this.tingkatanStudiFun(),
      vSelectAkreditasi: {
        options: [
          {
            label: 'A',
            value: 'A',
          },
          {
            label: 'B',
            value: 'B',
          },
          {
            label: 'C',
            value: 'C',
          },
        ],
        reduce({ value }) {
          return value
        },
      },
      // form
      form: {
        program_studi: '',
        jenis_program: '',
        peringkat_akreditasi_ps: '',
        nomor_sk: '',
        tanggal_kdl: '',
        nama_unit_pengelola: '',
        nama_perguruan_tinggi: 'Universitas Mulawarman',
        alamat: 'Jl. Kuaro, Gn. Kelua, Kec. Samarinda Ulu, Kota Samarinda, Kalimantan Timur',
        nomor_telp: '',
        email: '',
        website: '',
        ts_dari: '',
        ts_sampai: '',
        name_pengusul: '',
        tanggal: '',
        kota_kab: 'Kota Samarinda',
        kode_pos: '75119',

        // Data according to request schema
        get data() {
          return {
            user_id: getUserData().id,
            peringkat_akreditasi_ps: this.peringkat_akreditasi_ps,
            no_sk_ban_pt: this.nomor_sk,
            tgl_kadaluarsa: this.tanggal_kdl,
            nama_unit_pengelola: this.nama_unit_pengelola,
            nama_perguruan_tinggi: this.nama_perguruan_tinggi,
            alamat: this.alamat,
            kota_kab: this.kota_kab,
            kode_pos: this.kode_pos,
            no_telp: this.nomor_telp,
            email: this.email,
            website: this.website,
            ts: `${this.ts_dari}/${this.ts_sampai}`,
            name_pengusul: this.name_pengusul,
            tanggal: this.tanggal,
          }
        },
        set data(data) {
          if (data) {
            const [ts_dari, ts_sampai] = data.ts.split('/')
            this.program_studi = ''
            this.jenis_program = ''
            this.peringkat_akreditasi_ps = data.peringkat_akreditasi_ps
            this.nomor_sk = data.no_sk_ban_pt
            this.tanggal_kdl = data.tgl_kadaluarsa
            this.nama_unit_pengelola = data.nama_unit_pengelola
            this.nama_perguruan_tinggi = data.nama_perguruan_tinggi
            this.alamat = data.alamat
            this.nomor_telp = data.no_telp
            this.email = data.email
            this.website = data.website
            this.ts_dari = ts_dari
            this.ts_sampai = ts_sampai
            this.name_pengusul = data.name_pengusul
            this.tanggal = data.tanggal
            this.kota_kab = data.kota_kab
            this.kode_pos = data.kode_pos
          }
        },

        // Data master of form
        tsOptions: ['2018/2019'],
      },

      // loading
      loading: false,
      fetchLoading: false,

      // validations
      required,
      digits,
      email,
    }
  },
  watch: {
    'form.ts_dari': function (ts_dari) {
      this.form.ts_sampai = (ts_dari && ts_dari.length == 4) ? moment(ts_dari).add(1, 'year').format('YYYY') : '-'
    },
  },
  created() {
    this.fetchProfile()
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true

          useJwt.http.post('lkps_profile', this.form.data)
            .then(response => {
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })

              const data = JSON.parse(localStorage.getItem('userData'))
              // const ts = `${this.form.ts_dari}/${this.form.ts_sampai}`
              const { lkps_profile } = response.data
              data.lkps_profile = lkps_profile
              localStorage.setItem('userData', JSON.stringify(data))

              this.generateNewTS()
            })
            .catch(() => this.$alert({ title: 'Gagal mengubah profile.', variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.loading = false)
        }
      })
    },
    fetchProfile() {
      this.fetchLoading = true
      useJwt.http.get(`lkps_profile/${getUserData().id}`)
        .then(response => this.form.data = response.data.lkps_profiles)
        .finally(() => this.fetchLoading = false)
    },
    generateNewTS() {
      try {
        const ts = `${this.form.ts_dari}/${this.form.ts_sampai}`

        // if (ts !== oldTs) {
        useJwt.http.post('lkps_table', { ts })
          .then(response => {
            this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
          })
      } catch (err) {
        console.log(`error ${err}`)
      }
    },
    flatPickrOnReady(_, date) {
      this.form.tanggal = date
      this.form.tanggal_kdl = date
    },
    tingkatanStudiFun() {
      const tingkatan = {
        Sarjana: 'S',
        Magister: 'M',
        Doktor: 'D',
      }
      return `(${tingkatan[getUserData().departments[0].lkps_program.jenis_program]})`
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
